// import React from "react";
// import { Grid, IconButton } from "@material-ui/core";
// import { KeyboardArrowRight, KeyboardArrowLeft } from "@material-ui/icons";

// const TablePagination = props => {
//     console.log(props.meta);
//     const { totalAmountOfRows, limit, amountOfRowsReturned } = props.meta;
//     const page = (props.meta.page += 1);
//     const currentResults = `${limit * page - limit} - ${limit *
//         page} of ${totalAmountOfRows}`;

//     return (
//         <Grid
//             container
//             justify="space-between"
//             style={{
//                 padding: "12px",
//                 border: "1px solid rgba(0, 0, 0, 0.1)",
//                 background: " #d1d8ff1c"
//             }}
//         >
//             <Grid item>Displaying {currentResults}</Grid>
//             <Grid item>
//                 <Grid container>
//                     <IconButton
//                         aria-label="Prev"
//                         onClick={props.movePrev}
//                         size="small"
//                     >
//                         <KeyboardArrowLeft />
//                     </IconButton>
//                     <IconButton
//                         aria-label="Next"
//                         onClick={props.moveNext}
//                         size="small"
//                     >
//                         <KeyboardArrowRight />
//                     </IconButton>
//                 </Grid>
//             </Grid>
//         </Grid>
//     );
// };

// export default TablePagination;

import React from "react";

import TablePagination from "@material-ui/core/TablePagination";

const TablePaginationWrapper = props => {
    const handleChangePage = (e, page) => {
        console.log("Page:", page);
        props.setPage(page);
    };
    const handleChangeRowsPerPage = (e, page) => {
        console.log(e.target);
        props.setLimit(e.target.value);
    };
    const { totalAmountOfRows: count, limit, page } = props.meta;
    // const page = props.meta.page;
    console.log(props);

    return (
        <TablePagination
            rowsPerPageOptions={[10, 30, 100]}
            component="div"
            count={count}
            rowsPerPage={limit}
            page={page}
            backIconButtonProps={{
                "aria-label": "previous page"
            }}
            nextIconButtonProps={{
                "aria-label": "next page"
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    );
};

export default TablePaginationWrapper;
