import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { signin } from "../services/signinService";
import { toast } from "react-toastify";
import { parseJwt } from "../functions/utils";
import homeBg from "../images/homebg.jpg";
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="http://miracallsolutions.com/">
                Miracall Solutions
            </Link>{" "}
            {new Date().getFullYear()}
        </Typography>
    );
}

const styles = {
    root: {
        height: "100vh"
    },
    image: {
        backgroundImage: `url(${homeBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center"
    },
    paper: {
        margin: "12px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        margin: "12px"
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: "12px"
    },
    submit: {
        margin: "12px"
    }
};

class Signin extends Component {
    state = {};
    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        window.history.replaceState(null, null, window.location.pathname);
        if (params.get("so")) {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            window.location.reload();
        }
    }
    handleChange = ({ currentTarget: input }) => {
        this.setState({ [input.name]: input.value });
    };

    onSubmit = async e => {
        e.preventDefault();
        const { data: login } = await signin(this.state);
        if (login.token) {
            toast.success("Welcome");
            localStorage.setItem("user", JSON.stringify(parseJwt(login.token)));
            localStorage.setItem("token", login.token);

            //Im usnig a JS location sp the page should completely reload to affect the nav changes as well
            window.location.href = "/report";
        } else {
            toast.error(login.error[0].msg);
        }
    };

    render() {
        const { classes } = this.props;
        const { onSubmit, handleChange } = this;
        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square
                >
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <form className={classes.form} onSubmit={onSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={handleChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={handleChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Sign In
                            </Button>

                            <Box mt={5}>
                                <Copyright />
                            </Box>
                        </form>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(Signin);
