import React, { useState } from "react";
import { Link } from "react-router-dom";
import shortid from "shortid";

import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Collapse,
    Grid,
    TableSortLabel
} from "@material-ui/core";

const collapseComponent = props => (
    <td colSpan={"100%"}>
        {" "}
        {/* put the number of col of your table in this field */}
        <div className={props.className}>{props.children}</div>
    </td>
);

const TableContent = props => {
    const { columnNames, data, baseLink, queryParams } = props;
    const [collapse, setCollapse] = useState(0);
    const setOrderBy = column => {
        props.setOrderBy(column);
    };
    return (
        <Table>
            <TableHead>
                <TableRow>
                    {columnNames.map(columnName => (
                        <TableCell key={columnName.id}>
                            {columnName.id ? (
                                <TableSortLabel
                                    active={
                                        queryParams.orderBy === columnName.id
                                    }
                                    direction={queryParams.order}
                                    onClick={() => setOrderBy(columnName.id)}
                                >
                                    {columnName.name}
                                    {queryParams.orderBy === columnName.id &&
                                    columnName.id ? (
                                        <span
                                            style={{
                                                border: 0,
                                                clip: "rect(0 0 0 0)",
                                                height: 1,
                                                margin: -1,
                                                overflow: "hidden",
                                                padding: 0,
                                                position: "absolute",
                                                top: 20,
                                                width: 1
                                            }}
                                        >
                                            {queryParams.order === "desc"
                                                ? "sorted descending"
                                                : "sorted ascending"}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            ) : (
                                columnName.name
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody stripedrows="true">
                {data.map(row => (
                    <>
                        <TableRow
                            key={row.id}
                            onClick={() => {
                                setCollapse(collapse === row.id ? "" : row.id);
                            }}
                            component={baseLink && Link}
                            to={baseLink + "/" + row.id}
                            style={{
                                textDecoration: "none",
                                cursor: "pointer"
                            }}
                        >
                            {row.values.map(value => (
                                <TableCell key={shortid.generate()}>
                                    {value}
                                </TableCell>
                            ))}
                            {row.additional.map(value => (
                                <TableCell key={shortid.generate()}>
                                    {value}
                                </TableCell>
                            ))}
                        </TableRow>
                        <Collapse
                            in={collapse === row.id}
                            timeout="auto"
                            component={collapseComponent}
                            unmountOnExit
                        >
                            <div
                                container
                                style={{
                                    padding: "12px",
                                    border: "1px solid rgba(0, 0, 0, 0.1)",
                                    background: " #d1d8ff1c"
                                }}
                            >
                                {console.log(row.rest)}
                                <Grid container justify="space-between">
                                    {/* <Grid item xs={6}>
                                        <b>Desposition: </b>
                                        <br />
                                        {row.rest.disposition}
                                    </Grid> */}
                                    <Grid item xs={6}>
                                        <b>Notes: </b> <br />
                                        {row.rest.notes}
                                    </Grid>
                                </Grid>
                            </div>
                        </Collapse>
                    </>
                ))}
            </TableBody>
        </Table>
    );
};

export default TableContent;
