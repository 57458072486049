import axios from "axios";
// import logger from "./logService";
import { toast } from "react-toastify";

axios.defaults.headers.common["token"] = localStorage.getItem("token");

axios.interceptors.response.use(null, error => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;
    console.log(error.response);
    if (!expectedError) {
        // logger.log(error);

        toast.error(
            `An unexpected error occurrred ${error.response.request.responseURL}`
        );
    } else {
        if (error.response.status === 403) {
            window.location.href = "/";
        }
        toast.error(
            `An expected error occurrred In ${
                error.response.request.responseURL
            } ${JSON.stringify(error.response.data)}`
        );
    }

    return Promise.reject(error);
});
//axios.defaults.withCredentials = false;
export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete
};
