import React from "react";
import TableTop from "./TableTop";
import TableContent from "./TableContent";
import TablePagination from "./TablePagination";

const AdvancedTable = props => {
    const {
        columnNames,
        data,
        baseLink,
        meta,
        moveNext,
        movePrev,
        setPage,
        setLimit,
        setOrderBy,
        queryParams
    } = props;
    return (
        <React.Fragment>
            <TableTop />
            <TableContent
                columnNames={columnNames}
                data={data}
                baseLink={baseLink}
                setOrderBy={setOrderBy}
                queryParams={queryParams}
            />
            <TablePagination
                meta={meta}
                setPage={setPage}
                setLimit={setLimit}
            />
        </React.Fragment>
    );
};

export default AdvancedTable;
