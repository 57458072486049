import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Grid, Button } from "@material-ui/core";
import { isLoggedIn, getUser } from "../functions/utils";

export default function Navbar() {
    const classes = {};

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <Grid container justify="space-between">
                        <Typography variant="h6" className={classes.title}>
                            Miracall Solutions
                        </Typography>

                        <Typography className={classes.title}>
                            {isLoggedIn() ? (
                                <>
                                    Hello {getUser().name}
                                    <Button href="/?so=1" color="inherit">
                                        Logout
                                    </Button>
                                </>
                            ) : (
                                // <Button href="/" color="inherit">
                                //     Login
                                // </Button>
                                ""
                            )}

                            {/* {isLoggedIn() ? (
                                <Button href="/?so=1" color="inherit">
                                    Logout
                                </Button>
                            ) : (
                                ""
                            )} */}
                        </Typography>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    );
}
