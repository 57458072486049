import React from "react";
import { Grid } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
function CustomDate(props) {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
                value={new Date(Number(props.value))}
                onChange={props.onChange}
                inputVariant="outlined"
                margin="dense"
                style={{
                    width: "100%"
                }}
                format="MM/DD/YYYY"
            />
        </MuiPickersUtilsProvider>
    );
}

const ReportTop = props => {
    const updateFrom = v => {
        props.setFromTo(v.format("MM/DD/YYYY"), "");
    };
    const updateTo = v => {
        props.setFromTo("", v.format("MM/DD/YYYY"));
    };
    return (
        <Grid container>
            <CustomDate value={Date.parse(props.from)} onChange={updateFrom} />
            <CustomDate value={Date.parse(props.to)} onChange={updateTo} />
        </Grid>
    );
};

export default ReportTop;
