function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function(c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
}
function isLoggedIn() {
    return localStorage.getItem("token") || false;
}

function getUser() {
    return localStorage.getItem("token")
        ? JSON.parse(localStorage.getItem("user"))
        : {};
}

function objectToQueryString(params) {
    return typeof params === "object"
        ? "?" +
              Object.keys(params)
                  .map(key => key + "=" + params[key])
                  .join("&")
        : "";
}
export { parseJwt, isLoggedIn, getUser, objectToQueryString };
