import React from "react";
import {
    Route,
    Switch,
    BrowserRouter as Router,
    Redirect
} from "react-router-dom";
import Signin from "./components/Signin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./components/Navbar";
import Report from "./components/report/Report";
import { isLoggedIn } from "./functions/utils";

import "./App.css";

console.log("token", isLoggedIn());

function App() {
    return (
        <div>
            <Navbar />
            <ToastContainer />
            <Router>
                <Switch>
                    <Route path="/" exact component={Signin} />
                    {isLoggedIn() ? (
                        <Route path="/report" exact component={Report} />
                    ) : (
                        <Redirect to={"/"} />
                    )}
                </Switch>
            </Router>
        </div>
    );
}

export default App;
