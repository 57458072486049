import React, { Component } from "react";
import { Link } from "react-router-dom";
//Material-UI
import { Grid, Typography, Chip } from "@material-ui/core";

//Icons
import { CallReceived, CallMade, CallSplit } from "@material-ui/icons";

//Services
import { getReport } from "../../services/reportService";

//Custom
import AdvancedTable from "../../common/table/AdvancedTable";
import ReportTop from "./ReportTop";

const columnNames = [
    { name: "Timestamp", id: "date" },
    { name: "Call Time", id: "" },
    { name: "Call Type", id: "" },
    { name: "Customer Name", id: "" },
    { name: "Customer Number", id: "" },
    { name: "Disposition", id: "" }
];

function CallType(props) {
    const { callType } = props;
    let color = "#9dffbb";
    let icon = <CallMade />;
    switch (callType) {
        case "Inbound":
            color = "#d1d8ff";
            icon = <CallReceived />;
            break;
        case "3rd party transfer":
            color = "";
            icon = <CallSplit />;
            break;
    }

    return <Chip style={{ background: color }} label={callType} icon={icon} />;
}
function getOldDate(days) {
    const today = new Date();
    return new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - days
    );
}
const initQueryParams = {
    limit: 30,
    page: 0,
    from: getOldDate(7)
        .toISOString()
        .substring(0, 10),
    to: new Date().toISOString().substring(0, 10),
    order: "desc",
    orderBy: "date,hourOfDay"
};

class Report extends Component {
    state = {
        data: [],
        meta: {},
        queryParams: { ...initQueryParams }
    };

    setFromTo = async (from, to) => {
        let queryParams = { ...this.state.queryParams };
        if (from) queryParams.from = from;
        if (to) queryParams.to = to;
        queryParams.page = 0;

        this.setState(
            {
                queryParams
            },
            async () => await this.populateTable()
        );
    };

    // moveNext = async () => {
    //     const queryParams = { ...this.state.queryParams };

    //     queryParams.page = queryParams.page += 1;
    //     this.setState(
    //         {
    //             queryParams
    //         },
    //         async () => await this.populateTable()
    //     );
    // };

    // movePrev = async () => {
    //     const queryParams = { ...this.state.queryParams };

    //     queryParams.page = queryParams.page -= 1;
    //     if (queryParams.page < 0) return;
    //     this.setState(
    //         {
    //             queryParams
    //         },
    //         async () => await this.populateTable()
    //     );
    // };

    getReport = async () => {
        return await getReport([]);
    };

    setPage = async page => {
        console.log("Setting page to:", page);
        const queryParams = { ...this.state.queryParams };

        queryParams.page = page;
        this.setState(
            {
                queryParams
            },
            async () => await this.populateTable()
        );
    };
    setOrderBy = async column => {
        console.log("Setting Order By to:", column);
        const queryParams = { ...this.state.queryParams };

        if (queryParams.orderBy === column) {
            queryParams.order = queryParams.order == "asc" ? "desc" : "asc";
        }

        queryParams.orderBy = column;
        queryParams.page = 0;
        //queryParams.sort = `${queryParams.order === "asc" ? "" : "-"}${column}`;
        this.setState(
            {
                queryParams
            },
            async () => await this.populateTable()
        );
    };

    setLimit = async limit => {
        console.log("Setting Limit to:", limit);
        const queryParams = { ...this.state.queryParams };

        queryParams.limit = limit;
        this.setState(
            {
                queryParams
            },
            async () => await this.populateTable()
        );
    };

    populateTable = async () => {
        const all = await getReport(this.state.queryParams);
        const { data, meta } = all.data;

        const rows = data.map(row => ({
            id: row.id,
            values: [
                row.timestamp,
                row.callTime,
                <CallType callType={row.callType} />,
                row.customerName,
                row.number1,
                row.disposition
            ],
            additional: [],
            rest: row
        }));

        this.setState({
            data: rows,
            meta
        });
    };

    async componentDidMount() {
        await this.populateTable();
    }
    render() {
        const { data, queryParams, meta } = this.state;
        return (
            <Grid container>
                <Grid container justify="center">
                    <Grid item xs={10}>
                        <Grid container>
                            <Typography
                                variant="h4"
                                style={{ marginTop: " 12px" }}
                            >
                                Report
                            </Typography>
                            <ReportTop
                                setFromTo={this.setFromTo}
                                from={queryParams.from}
                                to={queryParams.to}
                            />
                            <AdvancedTable
                                columnNames={columnNames}
                                data={data}
                                meta={meta}
                                setPage={this.setPage}
                                setLimit={this.setLimit}
                                setOrderBy={this.setOrderBy}
                                setOrderBy={this.setOrderBy}
                                queryParams={this.state.queryParams}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default Report;
